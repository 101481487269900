define("discourse/plugins/waze-email-templates/discourse/admin-anonymous-email-log-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var o = {
      d: (a, e) => {
        for (var r in e) o.o(e, r) && !o.o(a, r) && Object.defineProperty(a, r, {
          enumerable: !0,
          get: e[r]
        });
      },
      o: (o, a) => Object.prototype.hasOwnProperty.call(o, a)
    },
    a = {};
  o.d(a, {
    A: () => e
  });
  const e = {
    resource: "admin.adminPlugins",
    path: "/plugins",
    map() {
      this.route("anonymousEmailLog", {
        path: "anonymous-email-log"
      });
    }
  };
  var r = _exports.default = a.A;
});