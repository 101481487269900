define("discourse/plugins/waze-email-templates/discourse/routes/admin-plugins-anonymous-email-log", ["exports", "admin/models/admin-user", "@ember/object", "discourse/lib/ajax", "discourse-common/lib/get-url", "discourse/routes/discourse"], function (_exports, e, t, r, o, a) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var s = {
      338: (a, s, u) => {
        u.d(s, {
          A: () => c
        });
        const d = (n = {
          default: () => e.default
        }, l = {}, u.d(l, n), l);
        var n, l;
        const p = (e => {
            var t = {};
            return u.d(t, e), t;
          })({
            computed: () => t.computed,
            default: () => t.default
          }),
          f = (e => {
            var t = {};
            return u.d(t, e), t;
          })({
            ajax: () => r.ajax
          }),
          i = (e => {
            var t = {};
            return u.d(t, e), t;
          })({
            default: () => o.default
          }),
          m = p.default.extend({
            open_rate: (0, p.computed)("sent", "opened", function () {
              return `${Math.round((this.opened || 0) / this.sent * 100)}%`;
            })
          });
        m.reopenClass({
          create(e) {
            return (e = e || {}).post_author && (e.post_author = d.default.create(e.post_author)), e.post_url && (e.post_url = (0, i.default)(e.post_url)), this._super(e);
          },
          findAll: e => (e = e || 0, (0, f.ajax)(`/admin/plugins/anonymous-email-log.json?offset=${e}`).then(e => e.map(e => m.create(e))))
        });
        const c = m;
      }
    },
    u = {};
  function d(e) {
    var t = u[e];
    if (void 0 !== t) return t.exports;
    var r = u[e] = {
      exports: {}
    };
    return s[e](r, r.exports, d), r.exports;
  }
  d.d = (e, t) => {
    for (var r in t) d.o(t, r) && !d.o(e, r) && Object.defineProperty(e, r, {
      enumerable: !0,
      get: t[r]
    });
  }, d.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t);
  var n = {};
  d.d(n, {
    A: () => m
  });
  const l = (p = {
    default: () => a.default
  }, f = {}, d.d(f, p), f);
  var p,
    f,
    i = d(338);
  const m = l.default.extend({
    model: () => i.A.findAll()
  });
  var c = _exports.default = n.A;
});