define("discourse/plugins/waze-email-templates/discourse/controllers/admin-plugins-anonymous-email-log", ["exports", "admin/models/admin-user", "@ember/object", "discourse/lib/ajax", "discourse-common/lib/get-url", "@ember/controller", "admin/models/email-log"], function (_exports, t, e, o, a, r, d) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var s = {
      338: (r, d, s) => {
        s.d(d, {
          A: () => c
        });
        const l = (n = {
          default: () => t.default
        }, i = {}, s.d(i, n), i);
        var n, i;
        const u = (t => {
            var e = {};
            return s.d(e, t), e;
          })({
            computed: () => e.computed,
            default: () => e.default
          }),
          m = (t => {
            var e = {};
            return s.d(e, t), e;
          })({
            ajax: () => o.ajax
          }),
          f = (t => {
            var e = {};
            return s.d(e, t), e;
          })({
            default: () => a.default
          }),
          p = u.default.extend({
            open_rate: (0, u.computed)("sent", "opened", function () {
              return `${Math.round((this.opened || 0) / this.sent * 100)}%`;
            })
          });
        p.reopenClass({
          create(t) {
            return (t = t || {}).post_author && (t.post_author = l.default.create(t.post_author)), t.post_url && (t.post_url = (0, f.default)(t.post_url)), this._super(t);
          },
          findAll: t => (t = t || 0, (0, m.ajax)(`/admin/plugins/anonymous-email-log.json?offset=${t}`).then(t => t.map(t => p.create(t))))
        });
        const c = p;
      }
    },
    l = {};
  function n(t) {
    var e = l[t];
    if (void 0 !== e) return e.exports;
    var o = l[t] = {
      exports: {}
    };
    return s[t](o, o.exports, n), o.exports;
  }
  n.d = (t, e) => {
    for (var o in e) n.o(e, o) && !n.o(t, o) && Object.defineProperty(t, o, {
      enumerable: !0,
      get: e[o]
    });
  }, n.o = (t, e) => Object.prototype.hasOwnProperty.call(t, e);
  var i = {};
  n.d(i, {
    A: () => h
  });
  const u = (m = {
    default: () => r.default
  }, f = {}, n.d(f, m), f);
  var m, f;
  const p = (t => {
    var e = {};
    return n.d(e, t), e;
  })({
    default: () => d.default
  });
  var c = n(338);
  const h = u.default.extend({
    loading: !1,
    init() {
      this._super(...arguments);
    },
    loadLogs(t) {
      if (!(t && this.loading || this.get("model.allLoaded"))) return this.set("loading", !0), c.A.findAll(t ? this.get("model.length") : null).then(e => {
        this.model && t && e.length < 50 && this.model.set("allLoaded", !0), this.model && t ? this.model.addObjects(e) : this.set("model", e);
      }).finally(() => this.set("loading", !1));
    },
    actions: {
      loadMore() {
        this.loadLogs(p.default, !0);
      }
    }
  });
  var g = _exports.default = i.A;
});