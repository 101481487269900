define("discourse/plugins/waze-email-templates/discourse/models/admin-plugins-anonymous-email-log", ["exports", "admin/models/admin-user", "@ember/object", "discourse/lib/ajax", "discourse-common/lib/get-url"], function (_exports, e, t, r, o) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var a = {
      d: (e, t) => {
        for (var r in t) a.o(t, r) && !a.o(e, r) && Object.defineProperty(e, r, {
          enumerable: !0,
          get: t[r]
        });
      },
      o: (e, t) => Object.prototype.hasOwnProperty.call(e, t)
    },
    u = {};
  a.d(u, {
    A: () => i
  });
  const s = (n = {
    default: () => e.default
  }, d = {}, a.d(d, n), d);
  var n, d;
  const l = (e => {
      var t = {};
      return a.d(t, e), t;
    })({
      computed: () => t.computed,
      default: () => t.default
    }),
    p = (e => {
      var t = {};
      return a.d(t, e), t;
    })({
      ajax: () => r.ajax
    }),
    m = (e => {
      var t = {};
      return a.d(t, e), t;
    })({
      default: () => o.default
    }),
    f = l.default.extend({
      open_rate: (0, l.computed)("sent", "opened", function () {
        return `${Math.round((this.opened || 0) / this.sent * 100)}%`;
      })
    });
  f.reopenClass({
    create(e) {
      return (e = e || {}).post_author && (e.post_author = s.default.create(e.post_author)), e.post_url && (e.post_url = (0, m.default)(e.post_url)), this._super(e);
    },
    findAll: e => (e = e || 0, (0, p.ajax)(`/admin/plugins/anonymous-email-log.json?offset=${e}`).then(e => e.map(e => f.create(e))))
  });
  const i = f;
  var c = _exports.default = u.A;
});